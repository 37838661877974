import { fetchWrapper } from "@/router";

const baseUrl = `https://subank.info:8090/users`;

export const saveBackend = {
  async saveMachine(machineNote, machineID) {
    console.log(machineNote);
    console.log(machineID);
    const response = await fetchWrapper.post(`${baseUrl}/savemachine`, {
      machineNote,
      machineID,
    });
    console.log(response);
  },
};
