<script setup>
import { ref, onMounted } from "vue";
const qrSize = ref(100); // Başlangıç değeri
onMounted(() => {
  // Burada size değerini dinamik olarak ayarlayabilirsiniz
  const screenWidth = window.innerWidth;

  // Ekran genişliğine göre qrSize değerini ayarla
  if (screenWidth < 450) {
    qrSize.value = 290; // Küçük ekranlar için
  } else {
    qrSize.value = 400; // Büyük ekranlar için
  }
});
</script>

<template>
  <!-- <h1>URL Parametresi: {{ id }}</h1>
    <h1>URL Parametresi: {{ pass }}</h1> -->
  <!-- <qrcode-vue :value="generatedURL" :size="300" level="H" />
    <h1>{{ generatedURL }}</h1> -->
  <div class="main">
    <div class="border">
      <qrcode-vue :value="generatedURL" :size="qrSize" level="H" class="qr" />
      <div class="text-center">
        <h3>Makina ID'si = {{ id }}</h3>
        <!-- <h3>Doğrulama Kodu = {{ pass }}</h3> -->
        <p>
          Okuttuğunuz QR altında bulunan doğrulama kodu ile ekranda gördüğünüz
          doğrulama kodunun aynı olduğundan emin olun.
        </p>
      </div>
      <a href="#" class="my-super-cool-btn">
        <div class="dots-container">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
        <span>Tamamla</span>
      </a>
    </div>
  </div>
</template>
<style lang="scss">
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  //background-color: aqua;
  //background-color: #f1f1f1;
  @media only screen and (max-width: 600px) {
    .border {
      width: 300px;
      .text-center {
        margin: 10px 0px;
        width: 40%;
      }
    }
  }
  .border {
    border: 5px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.049);
    width: 350px;
    .qr {
      width: 300px; /* İstediğiniz genişlik */
      height: 300px; /* İstediğiniz yükseklik */
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.1);
    justify-content: center;
    .text-center {
      margin: 10px 0px;
      width: 99%;
    }
    @import url("https://fonts.googleapis.com/css?family=Montserrat:900");

    body {
      margin: 0;
      padding: 0;
      background-color: #292929;
      font-family: "Montserrat", sans-serif;
    }
    #wrapper {
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .my-super-cool-btn {
      position: relative;
      text-decoration: none;
      color: #fff;
      letter-spacing: 1px;
      font-size: 2rem;
      box-sizing: border-box;
    }
    .my-super-cool-btn span {
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 200px;
    }
    .my-super-cool-btn span:before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      border-radius: 100%;
      border: 4px solid #f38014;
      box-sizing: border-box;
      transition: all 0.85s cubic-bezier(0.25, 1, 0.33, 1);
      box-shadow: 0 30px 85px rgba(13, 182, 204, 0.14),
        0 15px 35px rgba(0, 0, 0, 0.14);
    }
    .my-super-cool-btn:hover span:before {
      transform: scale(0.8);
      box-shadow: 0 20px 55px rgba(61, 212, 31, 0.274),
        0 15px 35px rgba(92, 219, 92, 0.14);
    }
    .my-super-cool-btn .dots-container {
      opacity: 0;
      animation: intro 1.6s;
      animation-fill-mode: forwards;
    }
    .my-super-cool-btn .dot {
      width: 4px;
      height: 4px;
      display: block;
      background-color: #f38b14;
      border-radius: 100%;
      position: absolute;
      transition: all 0.85s cubic-bezier(0.25, 1, 0.33, 1);
    }
    .my-super-cool-btn .dot:nth-child(1) {
      top: 300px;
      left: 300px;
      transform: rotate(-140deg);
      animation: swag1-out 0.3s;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .my-super-cool-btn .dot:nth-child(2) {
      top: 300px;
      right: 300px;
      transform: rotate(140deg);
      animation: swag2-out 0.3s;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .my-super-cool-btn .dot:nth-child(3) {
      bottom: 300px;
      left: 300px;
      transform: rotate(140deg);
      animation: swag3-out 0.3s;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .my-super-cool-btn .dot:nth-child(4) {
      bottom: 300px;
      right: 300px;
      transform: rotate(-140deg);
      animation: swag4-out 0.3s;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    .my-super-cool-btn:hover .dot:nth-child(1) {
      animation: swag1 0.3s;
      animation-fill-mode: forwards;
    }
    .my-super-cool-btn:hover .dot:nth-child(2) {
      animation: swag2 0.3s;
      animation-fill-mode: forwards;
    }
    .my-super-cool-btn:hover .dot:nth-child(3) {
      animation: swag3 0.3s;
      animation-fill-mode: forwards;
    }
    .my-super-cool-btn:hover .dot:nth-child(4) {
      animation: swag4 0.3s;
      animation-fill-mode: forwards;
    }
    @keyframes intro {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes swag1 {
      0% {
        top: 50px;
        left: 50px;
        width: 8px;
      }
      50% {
        width: 30px;
        opacity: 1;
      }
      100% {
        top: 20px;
        left: 20px;
        width: 8px;
        opacity: 1;
      }
    }
    @keyframes swag1-out {
      0% {
        top: 20px;
        left: 20px;
        width: 8px;
      }
      50% {
        width: 30px;
        opacity: 1;
      }
      100% {
        top: 50px;
        left: 50px;
        width: 8px;
        opacity: 0;
      }
    }
    @keyframes swag2 {
      0% {
        top: 50px;
        right: 50px;
        width: 8px;
      }
      50% {
        width: 30px;
        opacity: 1;
      }
      100% {
        top: 20px;
        right: 20px;
        width: 8px;
        opacity: 1;
      }
    }
    @keyframes swag2-out {
      0% {
        top: 20px;
        right: 20px;
        width: 8px;
      }
      50% {
        width: 30px;
        opacity: 1;
      }
      100% {
        top: 50px;
        right: 50px;
        width: 8px;
        opacity: 0;
      }
    }
    @keyframes swag3 {
      0% {
        bottom: 50px;
        left: 50px;
        width: 8px;
      }
      50% {
        width: 30px;
        opacity: 1;
      }
      100% {
        bottom: 20px;
        left: 20px;
        width: 8px;
        opacity: 1;
      }
    }
    @keyframes swag3-out {
      0% {
        bottom: 20px;
        left: 20px;
        width: 8px;
      }
      50% {
        width: 30px;
        opacity: 1;
      }
      100% {
        bottom: 50px;
        left: 50px;
        width: 8px;
        opacity: 0;
      }
    }
    @keyframes swag4 {
      0% {
        bottom: 50px;
        right: 50px;
        width: 8px;
      }
      50% {
        width: 30px;
        opacity: 1;
      }
      100% {
        bottom: 20px;
        right: 20px;
        width: 8px;
        opacity: 1;
      }
    }
    @keyframes swag4-out {
      0% {
        bottom: 20px;
        right: 20px;
        width: 8px;
      }
      50% {
        width: 30px;
        opacity: 1;
      }
      100% {
        bottom: 50px;
        right: 50px;
        width: 8px;
        opacity: 0;
      }
    }
  }
}
</style>
<script>
import QrcodeVue from "qrcode.vue";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    pass: {
      type: String,
      required: true,
    },
    url: "google.com",
  },
  //   mounted() {
  //     console.log("URL Parametresi:", this.s);
  //   },
  components: {
    QrcodeVue,
  },
  computed: {
    generatedURL() {
      const currentURL = window.location.origin;
      return `${currentURL}?id=${this.id}&pass=${this.pass}`;
    },
  },
};
</script>
