<script setup>
import { storeToRefs } from "pinia";
import { watch, ref } from "vue";
import {
  useAuthStore,
  useUsersStore,
  useNofiticationStore,
  useMachinesStore,
} from "@/stores";

const authStore = useAuthStore();
const { user: authUser } = storeToRefs(authStore);

const usersStore = useUsersStore();
const { users } = storeToRefs(usersStore);

const machineStore = useMachinesStore();
const { machines } = storeToRefs(machineStore);

const notificationStore = useNofiticationStore();
const { notifications } = storeToRefs(notificationStore);
const selectUser = ref("");
//get notifications by machineIDS
watch(
  authUser,
  (newVal, oldVal) => {
    // Yeni değer mevcut olduğunda ve machineID özelliği tanımlı olduğunda
    if (newVal && newVal.machineID) {
      console.log("Machine ID:", newVal.machineID);
      // machineID ile ilgili işlemleri burada yapabilirsiniz
      notificationStore.getAll(newVal.machineID);
    }
  },
  { immediate: true }
);
watch(selectUser, (newVal) => {
  console.log("Selected User ID:", newVal);
});

machineStore.getAll();
usersStore.getAll();
</script>

<template>
  <select v-model="selectUser">
    <option value="">Kullanıcı Seçimi Yap</option>
    <option v-for="user in users" :key="user" :value="user">
      {{ user.username }}
    </option>
  </select>
  <!-- list all machines by select -->
  <select name="machineSelect" id="01" :disabled="selectUser == 0">
    <option value="">Makina Seçimi Yap</option>
    <option
      v-for="machine in selectUser.machineID"
      :key="machine"
      :value="machine"
    >
      {{ machine }}
    </option>
  </select>
</template>
