export * from "./fetchWrapper.js";
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import SettingsView from "../views/SettingsView.vue";
import MyMachinesView from "../views/MyMachinesView.vue";
import MachinesView from "../views/MachinesView.vue";
import TestView from "@/views/TestView.vue";
import { useAuthStore } from "@/stores";

const routes = [
  {
    path: "/login",
    component: LoginView,
  },
  {
    path: "/settings",
    component: SettingsView,
  },
  {
    path: "/mymachines",
    component: MyMachinesView,
  },
  {
    path: "/machines",
    component: MachinesView,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/s",
    name: "TestView",
    component: TestView,
    props: (route) => ({ id: route.query.id, pass: route.query.t }),
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes,
});
//Giriş yapmadan işlem yapmalarını önlemek için router.beforeEach kullanıyoruz.
router.beforeEach(async (to) => {
  const loginPage = ["/login"];
  const authReq = !loginPage.includes(to.path);
  const auth = useAuthStore();
  //Kulanıcın geri dönüş yapmasını sağlamak için tam url belirlenir.
  if (authReq && !auth.user) {
    auth.returnUrl = to.fullPath;
    return "/login";
  }
});
export default router;
