import { defineStore } from "pinia";

import { fetchWrapper } from "@/router";

const baseUrl = `https://subank.info:8090/users`;

export const useUsersStore = defineStore({
  id: "users",
  state: () => ({
    users: {},
  }),
  actions: {
    async getAll() {
      this.users = { loading: true };
      fetchWrapper
        .get(baseUrl)
        .then((users) => (this.users = users))
        .catch((error) => console.error("hata var" + error));
    },
  },
});
