import { defineStore } from "pinia";

import { fetchWrapper } from "@/router";

const baseUrl = `https://subank.info:8090/users`;
const array = ["a", "b"];
function convertToIdArray(ids) {
  // Eğer ids zaten bir dizi ise, doğrudan döndür
  if (Array.isArray(ids)) {
    return ids;
  }
  // Eğer ids bir string ise, virgülle ayrılmış varsayarak diziye çevir
  if (typeof ids === "string") {
    return ids.split(",").map((id) => id.trim());
  }
  // Eğer hiçbiri değilse, boş bir dizi döndür
  return [];
}

export const useNofiticationStore = defineStore({
  id: "notification",
  state: () => ({
    notifications: {},
  }),
  actions: {
    async getAll(ids) {
      this.notifications = { loading: true };
      console.log(ids);
      const notifications = await fetchWrapper.post(
        `${baseUrl}/notifications`,
        ids
      );
      console.log(notifications);
      this.notifications = notifications;
    },
  },
});
